import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "クリエイターのストーリーに振り切ったサイトを作った話",
  "date": "2018-11-30T03:29:42.000Z",
  "slug": "entry/2018/11/30/122942",
  "tags": ["medley"],
  "hero": "./2018_11_30.png",
  "heroAlt": "creator site"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。デザイナーの波切（はきり）です。`}</p>
    <p>{`普段は`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`のデザイン全般を担当しておりますが、今回は`}<a parentName="p" {...{
        "href": "/entry/2018/11/22/181939"
      }}>{`マエダからのバトン`}</a>{`を受け、先日リリースをしたクリエイターページのリニューアルの経緯について書かせていただきます。`}</p>
    <p>{`「MEDLEY DESIGN & ENGINEERING」という言葉に秘められた想いはマエダからも説明がありましたが、メドレーでは「ビジネス」「テクノロジー」「クリエイティブ」の 3 軸の横断的な理解と実践を志向しており、この文化を表わす言葉として「MEDLEY DESIGN & ENGINEERING」を掲げたクリエイター向けのページを公開しています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181130/20181130120407.png",
        "alt": "f:id:medley_inc:20181130120407p:plain",
        "title": "f:id:medley_inc:20181130120407p:plain"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>
    <p>{`2016 年から公開していたこちらのページも、時間と共に伝えたい内容が少しずつ変化してきていたこと、`}<a parentName="p" {...{
        "href": "https://drive.medley.jp/"
      }}>{`MEDLEY DRIVE`}</a>{`というプロジェクト公開に際して、より広範な分野のクリエイターにメドレーへの興味を持ってもらいたいことから、今回のリニューアルがスタートしました。`}</p>
    <h1>{`何を伝えたかったのか`}</h1>
    <p>{`今回はインタビューを主軸にした構成となっていますが、これはエンジニアリングやデザインなどのスキルを持つ人が、社会の課題解決のためにその力を発揮したいと考えた時、このサイトを思い出してその背中を後押しできるようなサイトにしたい、と思ったのがきっかけになっています。`}</p>
    <p>{`リニューアル前のクリエイターページでは Q&A 形式で業務内容などを紹介する構成でしたが、読んだ人の記憶に残るものにするためにも、各メンバーの境遇などから多くの人の共感を呼べるストーリーが必要になると思い、人物を主軸としたインタビュー構成にと考えました。`}</p>
    <p>{`MEDLEY DESIGN & ENGINEERING というテーマから事前イメージとしてページのラフデザインなどの検討はしましたが、インタビューが主軸となる今回の制作はデザイン案作成よりもまず先に取材・撮影を行う`}<strong parentName="p">{`エディトリアルドリブンでの進め方`}</strong>{`となりました。`}</p>
    <h2>{`それぞれに志向するものがありつつも、メドレーとしての課題解決に尽力していること`}</h2>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181130/20181130120514.jpg",
        "alt": "f:id:medley_inc:20181130120514j:plain",
        "title": "f:id:medley_inc:20181130120514j:plain"
      }}></img></p>
    <p>{`インタビューを進めていく内に見えてきたのは、自己成長のため、身近な医療体験など、それぞれに入社理由がありつつもメドレーのビジョンを理解し、`}<strong parentName="p">{`医療ヘルスケア分野の課題解決のために尽力するメンバーの姿`}</strong>{`でした。`}</p>
    <p>{`入り口は違えど、メドレーに参画して課題解決に取り組むまでのストーリーは「なぜメドレーで働くのか？」といった問いに寄り添うものになっていると思います。`}</p>
    <h2>{`プロフェッショナルさと覚悟`}</h2>
    <p>{`メンバーへのインタビューの他にもエンジニア・デザイナーが所属する開発本部という組織のマインドセットを掲載しており、自律し自走する組織を目指す開発本部の目指すところが、各メンバーに浸透していることも読み取ることができます。`}</p>
    <p>{`MEDLEY DESIGN & ENGINEERING というテーマの本質である課題解決のために職種やキャリアを問わないスキルの越境、組織パフォーマンスの最大化、社会への貢献といったキーワードが各自の言葉で語られています。この一貫性は「メドレーらしさ」や開発本部の文化を象徴しており、インタビューの中でも特筆すべきポイントになっています。`}</p>
    <h1>{`そのために何をしたのか`}</h1>
    <p>{`サイトで伝えたいこと・インタビューを通して伝えたいことが出てきたところでそれをどう表現するかですが、前述した通り今回はエディトリアルドリブンで大半の表現をすることができました。`}</p>
    <h3><strong parentName="h3">{`内省とストーリーのエディトリアル`}</strong></h3>
    <p>{`制作クレジットにある通り、今回は取材・編集に小山さん`}<a parentName="p" {...{
        "href": "https://twitter.com/kkzyk"
      }}>{`@kkzyk`}</a>{`、撮影に`}<a parentName="p" {...{
        "href": "https://cargocollective.com/shunsukeimai"
      }}>{`今井さん`}</a>{`を迎えてインタビュー取材と撮影を行っています。`}</p>
    <p>{`対外的に情報の少ない社内の人間をまとめて取材するのは人物理解などハードルが高いですが果敢にも挑戦していただき、アウトプットもいわゆる A 対 B の対話形式ではなく、独白を交えたインタビュアー視点での編集となっており、人物に焦点を当てつつストーリーとして読み取りやすい文体に仕上げていただきました。`}</p>
    <p>{`今井さんの表情を引き出す写真も相まって、メンバー自身のこれまでを振り返る内省とストーリーを垣間見るような編集が出来たのではないかと思います。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181130/20181130120537.jpg",
        "alt": "f:id:medley_inc:20181130120537j:plain",
        "title": "f:id:medley_inc:20181130120537j:plain"
      }}></img></p>
    <h2>{`編集の熱量で作り上げたデザイン`}</h2>
    <p>{`編集のブラッシュアップとデザインや実装のブラッシュアップはほぼ同時進行で進めていき、デザインや文言の細かな調整などもその場で実装しなおしアップデートしていくスタイルで制作しました。`}</p>
    <p>{`デザイン面では各クリエイターの内省とストーリーを読み手に伝えるために、読み心地や読後の余韻を残すべく余白を活かしたレイアウトにしました。`}</p>
    <p>{`また、写真と文章を活かすようなデザインを心がけました。写真は大きいディスプレイでも迫力を追求するために全面を使いつつ、文章は 2 分割のレイアウトで文章幅を広げすぎず読みやすさを確保しました。`}</p>
    <p>{`スマートフォンでも読みやすさを担保するため文字サイズにこだわり、結果としてどのデバイスでも読みやすく、文章と写真が伝わるためのデザインであることを心がけて制作しています。`}</p>
    <p>{`このようなシンプルで装飾のないレイアウトで勝負できたのも、写真や文章と構成の力強さあってのものであり、こういった質実剛健さは`}<a parentName="p" {...{
        "href": "/entry/2018/10/10/120246"
      }}>{`オフィスのデザイン`}</a>{`などにも通じるメドレーらしさの表出となっているかもしれません。`}</p>
    <h1>{`作ってみて`}</h1>
    <p>{`元々はクリエイター採用ページとしてリニューアルを検討していましたが、インタビュー形式に振り切ったことで、今回 CREATOR’S STORY というサブタイトルをつけ、あからさまな採用色をなくしました。こうした実験的な編集やデザインとして表現できたのも、日頃から`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/feed/s/medley"
      }}>{`入社理由シリーズ`}</a>{`や`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/feed/s/medley-int"
      }}>{`聞いてみたシリーズ`}</a>{`などバラエティ豊かな情報発信を行っているメドレーの土台があるからと考えています（いつもありがとうございます！）。`}</p>
    <p>{`ここまで書いたように、自身のスキルを社会の課題解決のために発揮したいと考えた時にふと思い出していただきたく、こういったページがあることを記憶に留めていただけましたら幸いです。`}</p>
    <p>{`事業会社のデザイナーとして現在は「介護のほんね」をメインで担当しているのですが、クリエイターサイトやオフィス移転など、事業だけなくコーポレートに関わるさまざまなデザインに携わる機会があります。`}</p>
    <p>{`このようにやるべきことが多くありチャンスとやりがいが溢れる今、ブランディングとプロダクトの両輪を同時に頑張りたい方、医療介護領域の課題解決のために腕を奮ってみたいと考える方、ぜひ気軽に弊社に遊びにきて話をしてみませんか？`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医療介護の人材採用システム「ジョブメドレー」や、医師たちがつくるオンライン医療事典「MEDLEY」、医療につよい介護施設・老人ホームの検索メディア「介護のほんね」、オンライン診療アプリ「CLINICS」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY DESIGN&ENGINEERING" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>
    <p>{`少しだけ興味があるという方も、ぜひお気軽にご連絡ください！ お待ちしております。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      